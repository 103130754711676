import React from 'react';
import {
    BrowserRouter as Router, Routes, Route,
} from 'react-router-dom';
import Home from './components/Home/Home';
import Contact from './components/Contact/Contact';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Posts from "./components/News/Posts/Posts";
import SinglePost from "./components/News/SinglePost/SinglePost";
import Registration from './components/Authentication/Registration';
import PatientRegistrationDetails from './components/Authentication/patient/PatientRegistrationDetails';
import SignIn from './components/Authentication/SignIn';
import {AuthProvider} from './context/AuthContext';
import 'leaflet/dist/leaflet.css';
import './App.css';
import ResearcherRegistrationDetails from "./components/Authentication/researcher/ResearcherRegistrationDetails";
import HealthcareExpertRegistrationDetails
    from "./components/Authentication/healthcare expert/HealthcareExpertRegistrationDetails";
import CareCenterRegistrationDetails from "./components/Authentication/care center/CareCenterRegistrationDetails";
import PatientOrganizationRegistrationDetails
    from "./components/Authentication/Patient Organization RegistrationDetails";
import ResearchOrganizationRegistrationDetails
    from "./components/Authentication/research organization/ResearchOrganizationRegistrationDetails";
import CompanyRegistrationDetails from "./components/Authentication/company/CompanyRegistrationDetails";
import MyProfile from "./components/MyProfile/MyProfile";

function App() {
    return (<Router>
        <AuthProvider>
            <div className="App">
                <Header/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/blog" element={<Posts/>}/>
                    <Route path="/blog/:id" element={<SinglePost/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/register" element={<Registration/>}/>
                    <Route path="/register-patient" element={<PatientRegistrationDetails/>}/>
                    <Route path="/register-researcher" element={<ResearcherRegistrationDetails/>}/>
                    <Route path="/register-healthcare-expert" element={<HealthcareExpertRegistrationDetails/>}/>
                    <Route path="/register-care-center" element={<CareCenterRegistrationDetails/>}/>
                    <Route path="/register-patient-organization" element={<PatientOrganizationRegistrationDetails/>}/>
                    <Route path="/register-research-organization" element={<ResearchOrganizationRegistrationDetails/>}/>
                    <Route path="/register-company" element={<CompanyRegistrationDetails/>}/>
                    <Route path="/login" element={<SignIn/>}/>
                    <Route path="/profile" element={<MyProfile/>}/>
                </Routes>
                <Footer/>
            </div>
        </AuthProvider>
    </Router>);
}

export default App;