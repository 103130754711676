import React from 'react';
import './Footer.css';

const Footer = () => {
    return (<footer className="footer">
        <div className="footer-content">
            {/* Left Section - Organization Info */}
            <div className="footer-section">
                <img src="/logo_coloured.png" alt="Duchenne Data Foundation" className="footer-logo"/>
                <p className="footer-description">
                    We bring together fragmented data pieces of the Duchenne and Becker muscular dystrophy
                    (DMD/BMD) world and test innovative solutions, scaling them to make a global impact.
                </p>
            </div>

            {/* Center Section - Quick Links */}
            <div className="footer-section">
                <h3>QUICK LINKS</h3>
                <a href="https://www.duchennedatafoundation.org/" rel="noreferrer" target="_blank">Duchenne Data
                    Foundation</a>
                <a href="https://repository.duchennedatafoundation.org/" rel="noreferrer" target="_blank">Duchenne Data
                    Repository</a>
            </div>
        </div>

        {/* Bottom Section */}
        <div className="footer-bottom">
            <p>Copyright © 2025 Duchenne Data Foundation. All rights reserved.
                Pictures by <a href="https://unsplash.com/">Unsplash</a> and <a href="https://stock.adobe.com/">Adobe
                    Stock</a>.
            </p>
            <div className="footer-links">
                <a href="/documents/Privacy%20Policy.pdf" target="_blank">Privacy Policy</a>
                <a href="/documents/Security%20Policy.pdf" target="_blank">Security Policy</a>
                <a href="/documents/Terms%20and%20Conditions.pdf" target="_blank">Terms and Conditions</a>
            </div>
        </div>
    </footer>);
};

export default Footer;
