import React from 'react';
import './HeroSection.css';

const HeroSection = () => {
    return (<section className="hero-section">
        <div className="hero-image">
            <img src="images/hero.png" alt="Child"/>
        </div>
        <div className="hero-content">
            <h1>Your guide in finding reliable information to make better healthcare decisions.</h1>
            <p>
                Find and structure specialized DMD healthcare in a way that's best for you. Access disease specific
                up-to-date information. All in one place.
            </p>
            <button
                onClick={() => window.location.href = 'https://www.duchennedatafoundation.org/project/duchenne-map/'}>MORE
                ABOUT DUCHENNE MAP
            </button>
        </div>
    </section>);
};

export default HeroSection;
