import React from 'react';
import Modal from 'react-modal';
import './ReusableModal.css'; // Add this for custom styling

// Ensure that react-modal is bound to the app element for accessibility
Modal.setAppElement('#root');

const ReusableModal = ({ isOpen, onClose, title, message, buttonText }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="modal-content"
            overlayClassName="modal-overlay"
            contentLabel="Popup Message"
            closeTimeoutMS={200} // Optional: adds animation when opening/closing
        >
            <div className="modal-header">
                <h2>{title}</h2>
            </div>
            <div className="modal-body">
                <p>{message}</p>
            </div>
            <div className="modal-footer">
                <button onClick={onClose} className="close-button">
                    {buttonText || 'Close'}
                </button>
            </div>
        </Modal>
    );
};

export default ReusableModal;
