import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {auth} from '../../firebase-config'; // Ensure you're importing auth from where you've initialized it
import {sendEmailVerification, signInWithEmailAndPassword, signOut, sendPasswordResetEmail} from 'firebase/auth';
import ReusableModal from '../ReusableModal';
import './SignIn.css';

const SignIn = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '', password: ''
    });
    // Add a state to manage the loading state
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState, [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const userCredential = await signInWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;

            if (!user.emailVerified) {
                // Send a verification email if the user's email is not verified.
                await sendEmailVerification(user);
                setModalMessage("Please enter your email address first.");
                setIsModalOpen(true);
                await signOut(auth); // Optionally, sign out the user after sending the verification email.
                alert("A verification email has been sent. Please verify your email before signing in.");
            } else {
                // Proceed with your app's logged-in experience.
                navigate('/');
            }
        } catch (error) {
            console.error('Error signing in:', error);
            // Handle error (e.g., user not found, wrong password, etc.)
        } finally {
            setIsLoading(false);
        }
    };

    // Function to handle the "Forgot Password" functionality
    const handleForgotPassword = async () => {
        if (!formData.email) {
            setModalMessage("Please enter your email address first.");
            setIsModalOpen(true);
            return;
        }

        try {
            await sendPasswordResetEmail(auth, formData.email);
            setModalMessage("If a user account exists, a password reset email will be sent to your email address shortly.");
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error sending password reset email:', error);
            setModalMessage("Failed to send password reset email. Please try again.");
            setIsModalOpen(true);
        }
    };

    return (<div>
        <div className="registration-header">
            <h1 className="registration-title">Sign In</h1>
        </div>
        <div className="form-container">
            <form onSubmit={handleSubmit} className="sign-in-form">
                <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                {/* Conditionally render the button or loading animation */}
                {isLoading ? (<div className="loading-animation">Loading...</div> // Placeholder for your loading animation
                ) : (<>
                    <button type="submit" className="sign-in-button">Sign In</button>
                    <button
                        type="button"
                        className="forgot-password-button"
                        onClick={handleForgotPassword}
                    >
                        Forgot Password?
                    </button>
                </>)}
            </form>
            <ReusableModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title="Notification"
                message={modalMessage}
                buttonText="Close"
            />
        </div>
    </div>);
};

export default SignIn;
