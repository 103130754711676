// Header.js

import React from 'react';
import {signOut} from 'firebase/auth';
import {useAuth} from '../../context/AuthContext';
import {auth} from '../../firebase-config';
import {Link} from "react-router-dom";
import './Header.css'; // Make sure the CSS is imported
import {FaUserCircle} from "react-icons/fa";

const Header = () => {
    const {currentUser} = useAuth();
    const handleLogout = async () => {
        try {
            await signOut(auth);
            // Optionally, handle any post-logout logic like redirecting
        } catch (error) {
            console.error('Failed to log out:', error);
        }
    };

    return (<header className="header">
        <Link className={'header-logo'} to={'/'}>
            <img src="logo.png" alt="Logo"/>
        </Link>

        {/*<nav className="header-nav">*/}
        {/*    <a href="/about">About</a>*/}
        {/*    <a href="/blog">Blog</a>*/}
        {/*    <a href="/Contact">Contact</a>*/}
        {/*</nav>*/}

        <div className="header-buttons">
            {currentUser ? (<>
                <Link to="/profile" className="profile-button">
                    <FaUserCircle className="profile-icon"/>
                </Link>
                <button className="button" onClick={handleLogout}>Logout</button>
            </>) : (<>
                <button className="button" onClick={() => window.location.href = '/login'}>Sign In</button>
                <button className="button" onClick={() => window.location.href = '/register'}>Register</button>
            </>)}
        </div>

        {/* <div className="header-buttons">
        <button className="button" onClick={() => window.location.href='/login'}>Login</button>
        <button className="button" onClick={() => window.location.href='/register'}>Register</button>
      </div> */}
    </header>);
};

export default Header;
