// Authentication.js
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './Registration.css'; // Ensure you create a corresponding CSS file

const Registration = () => {
    const [role, setRole] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (role) {
            case 'patient':
                navigate('/register-patient');
                break;
            case 'researcher':
                navigate('/register-researcher');
                break;
            case 'healthcare':
                navigate('/register-healthcare-expert');
                break;
            case 'care_center':
                navigate('/register-care-center');
                break;
            case 'patient_org':
                navigate('/register-patient-organization');
                break;
            case 'research_org':
                navigate('/register-research-organization');
                break;
            case 'company':
                navigate('/register-company');
                break;
            default:
                navigate('/register-patient');
                break;
        }
    };

    return (
        <div className="registration-container">
            <h1>Duchenne Map connects the world.</h1>
            <p>Join the global Duchenne family and find reliable DMD information from verified sources.</p>
            <p>Register today and help create a better future for Duchenne patients and families.</p>
            <form className="registration-form" onSubmit={handleSubmit}>
                <label htmlFor="role">Sign up as</label>
                <select id="role" name="role" value={role} onChange={(e) => setRole(e.target.value)}>
                    <option value="">Please choose ...</option>
                    <option value="patient">Patient, Carrier or Caregiver</option>
                    <option value="researcher">Researcher</option>
                    <option value="healthcare">Healthcare Expert</option>
                    <option value="care_center">Care Center</option>
                    <option value="patient_org">Patient Organization</option>
                    <option value="research_org">Research Organization</option>
                    <option value="company">Company</option>
                </select>
                <button type="submit" className="register-button">Register</button>
            </form>
        </div>
    );
}

export default Registration;
