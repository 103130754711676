import React, {useState, useRef} from 'react';
import {auth, db} from '../../../firebase-config';
import {createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth';
import {doc, setDoc, GeoPoint} from 'firebase/firestore';
import {MapContainer, TileLayer, Marker, Popup, useMap} from 'react-leaflet';
import ReCAPTCHA from 'react-google-recaptcha';
import ReusableModal from '../../ReusableModal';
import 'leaflet/dist/leaflet.css';

// Helper component to handle map interaction
const SetViewOnClick = ({coords}) => {
    const map = useMap();
    map.setView(coords, 15);
    return null;
};

const PatientRegistrationForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        permissionToContactPatients: false,
        permissionToContactOrganizations: false,
        address: '',
        location: {lat: 0, lng: 0}
    });
    const [suggestions, setSuggestions] = useState([]);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const mapRef = useRef();

    // Handle reCAPTCHA change
    const onRecaptchaChange = (value) => {
        setRecaptchaValue(value); // Store the reCAPTCHA value
    };

    // Handle Nominatim search
    const handleAddressChange = async (e) => {
        const address = e.target.value;
        setFormData(prevState => ({...prevState, address}));

        if (address.length > 2) { // Start searching after typing 3 characters
            try {
                const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`);
                const data = await response.json();
                setSuggestions(data);
            } catch (error) {
                console.error('Error fetching address suggestions:', error);
            }
        } else {
            setSuggestions([]);
        }
    };

    // Select an address from suggestions
    const handleSelectAddress = (place) => {
        const location = {
            lat: parseFloat(place.lat), lng: parseFloat(place.lon)
        };
        setFormData(prevState => ({
            ...prevState, address: place.display_name, location
        }));
        setSuggestions([]);

        // Move the map to the selected location
        if (mapRef.current) {
            mapRef.current.setView(location, 15); // Adjust zoom level as needed
        }
    };

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData(prevState => ({
            ...prevState, [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!recaptchaValue) { // Check if reCAPTCHA is completed
            console.error('Please complete the reCAPTCHA');
            setModalMessage("Please complete the reCAPTCHA");
            setIsModalOpen(true);
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            // Handle password mismatch
            console.error('Passwords do not match');
            setModalMessage("Passwords do not match");
            setIsModalOpen(true);
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;

            // Send verification email
            await sendEmailVerification(user);
            setModalMessage("Thank you for signing up! To complete your registration and verify your account, please check your email inbox for a verification message we’ve just sent to you.");
            setIsModalOpen(true);
            console.log('Verification email sent.');

            // Create a GeoPoint using the latitude and longitude
            const geopoint = new GeoPoint(formData.location.lat, formData.location.lng);

            await setDoc(doc(db, 'users', user.uid), {
                role: 'healthcare expert',
                name: formData.name,
                email: formData.email,
                permissionToContactPatients: formData.permissionToContactPatients,
                permissionToContactOrganizations: formData.permissionToContactOrganizations,
                address: formData.address,
                location: geopoint
            });
            console.log('User registered with role:', user.uid);
        } catch (error) {
            console.error('Error registering user:', error.message);
        }
    };

    return (<div>
        <div className="registration-header">
            <h1 className="registration-title">Healthcare Expert Organization Registration</h1>
            <p className="registration-subtitle">
                With you joining this collaboration, we’re one step closer to connect the global Duchenne community,
                regardless of fields and borders. Please fill in the form below to access Duchenne Map and participate
                in
                creating a global network of specialised DMD care.
            </p>
        </div>
        <div className="registration-form-container">
            <form onSubmit={handleSubmit} className="registration-form">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        placeholder="Healthcare Expert Organization Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="john.doe@example.com"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleAddressChange}
                        placeholder="Enter your address"
                        required
                    />
                    {suggestions.length > 0 && (<ul className="suggestions-dropdown">
                        {suggestions.map((suggestion, index) => (
                            <li key={index} onClick={() => handleSelectAddress(suggestion)}>
                                {suggestion.display_name}
                            </li>))}
                    </ul>)}
                </div>
                <div className="map-container">
                    <MapContainer
                        center={formData.location}
                        zoom={formData.location.lat !== 52.26191870193713 || formData.location.lng !== 5.485825844485596 ? 15 : 5} // Default zoom level
                        style={{height: '200px', width: '100%'}}
                        whenCreated={mapInstance => mapRef.current = mapInstance}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={formData.location}>
                            <Popup>{formData.address || 'Default Location'}</Popup>
                        </Marker>
                        <SetViewOnClick coords={formData.location}/>
                    </MapContainer>
                </div>
                <div className="form-group-checkbox">
                    <label htmlFor="permissionToContactPatients">
                        <input
                            type="checkbox"
                            id="permissionToContactPatients"
                            name="permissionToContactPatients"
                            checked={formData.permissionToContactPatients}
                            onChange={handleChange}
                        />
                        Permission to be contacted by other patients
                    </label>
                </div>
                <div className="form-group-checkbox">
                    <label htmlFor="permissionToContactOrganizations">
                        <input
                            type="checkbox"
                            id="permissionToContactOrganizations"
                            name="permissionToContactOrganizations"
                            checked={formData.permissionToContactOrganizations}
                            onChange={handleChange}
                        />
                        Permission to be contacted by patient organizations
                    </label>
                </div>
                <div className="form-group">
                    <ReCAPTCHA
                        sitekey="6Ld4UUkqAAAAAPtC-sfrkW1ZURPBTsxJ-PhpicQr"
                        onChange={onRecaptchaChange}
                    />
                </div>
                <button type="submit" className="register-button">Register</button>
                <div className="privacy-text">
                    By signing up, I agree to the Duchenne Map Privacy Policy and Terms of Service
                </div>
            </form>
            <ReusableModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title="Notification"
                message={modalMessage}
                buttonText="Close"
            />
        </div>
    </div>);

};

export default PatientRegistrationForm;
